import { Component } from '@angular/core';

@Component({
  selector: 'app-casos-de-sucesso',
  templateUrl: './casos-de-sucesso.component.html'
})
export class CasosDeSucessoComponent {
  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/imgs/client/matheus-alves-contag-gyn.png",
      name: "Matheus Alves",
      designation: "Encarregado Contabil, atua no Departamento Contábil a 10 anos",
      message: `"A ferramenta Extrato OFX otimizou nosso trabalho, antigamente precisávamos de várias horas e passar por vários conversores para ter o mesmo resultado que a Extrato OFX entrega, em somente um lugar."`
    },
    /**
     * A ferramenta Extrato OFX otimizou nosso trabalho, antigamente precisávamos de várias horas e passar por vários conversores para ter o mesmo resultado que a Extrato OFX entrega, em somente um lugar.

Matheus Alves, Encarregado Contabil, atua no Departamento Contábil a 10 anos.
     */
    {
      profile: "assets/imgs/client/02.jpg",
      name: "Barbara McIntosh",
      designation: "M.D",
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
    },
    {
      profile: "assets/imgs/client/03.jpg",
      name: "Carl Oliver",
      designation: "P.A",
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
    },
    {
      profile: "assets/imgs/client/04.jpg",
      name: "Christa Smith",
      designation: "Manager",
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
    },
    {
      profile: "assets/imgs/client/05.jpg",
      name: "Dean Tolle",
      designation: "Developer",
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
    },
    {
      profile: "assets/imgs/client/06.jpg",
      name: "Jill Webb",
      designation: "Designer",
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`
    }
  ];

}
