import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SecurityUtil } from "src/app/_core/utils/security.util";
import { NovaAssinaturaV1Model } from "../models/nova-assinatura-v1.model";
import { ResultV1Model } from "src/app/shared/models/result-v1.model";
import { API_ASAAS, API_CONVERSOR } from "src/app/_core/api";
import { ConfiguracoesEnvioNfAsaasV1Model } from "../models/configuracoes-envio-nf-asaas-v1.model";
import { DeleteAssinaturaV1Model } from "../models/delete-assinatura-v1.model";

@Injectable({
  providedIn: 'root'
})
export class ComunicacaoApiAssinaturaV1Service{
  constructor(
    private httpClient: HttpClient,
    private securityUtil: SecurityUtil
  ) {}

  postNovaAssinatura(assinatura: NovaAssinaturaV1Model){
    return this.httpClient.post<ResultV1Model>(
      `${API_CONVERSOR}/v1/signatures/new`,
      assinatura,
      SecurityUtil.hasToken() ? {headers: this.securityUtil.composeHeader()} : {headers: this.securityUtil.composeHeaderConversor()}
    );
  };

  postConfiguracoesEmpresaParaEnvioNFAsaas(configuracoes: ConfiguracoesEnvioNfAsaasV1Model){
    return this.httpClient.post<ResultV1Model>(
      `${API_ASAAS}/v1/ofx/signatures/:id/invoice-settings`,
      configuracoes,
      {headers: this.securityUtil.composeHeaderAsaas()}
    );
  }

  deleteAssinatura(dados: DeleteAssinaturaV1Model){
    return this.httpClient.delete<ResultV1Model>(
      `${API_CONVERSOR}/v1/signatures/delete`,
      {
        body: dados,
        headers: this.securityUtil.composeHeader()
      },
    )
  }
}