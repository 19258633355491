<section id="endereco-faturamento">
  <form [formGroup]="formEnderecoFaturamento">
    <div class="row g-3">
      <div class="col-12 col-md-4 col-lg-3">
        <label class="form-label">
          CEP: <b class="text-danger">*</b>
        </label>
        <input type="text" class="form-control form-control-sm" formControlName="cepTitular"
          (input)="masksUtils.maskCep($event)" maxlength="8" (change)="aoInformarOCep()"
          (keyup)="proximoInput('cepTitular', 8, 'numeroEndereco')"
          [ngClass]="formReativo.definindoClasseValidator(formEnderecoFaturamento, 'cepTitular')">
        @if(controls['cepTitular']?.dirty){
        @if(controls['cepTitular'].errors?.['required']){
        <small class="text-danger">*Campo obrigatório.</small>
        }@else if(!isCepValido) {
        <small class="text-danger">*Informe um CEP válido.</small>
        }
        }
      </div>
      <div class="col-8 col-md-8 col-lg-6">
        <label class="form-label">
          Logradouro:
        </label>
        <input type="text" class="form-control form-control-sm" formControlName="logradouro"
          (change)="emitirEnderecoFaturamento()">
      </div>
      <div class="col-4 col-md-2 col-lg-3">
        <label class="form-label">
          Nº: <b class="text-danger">*</b>
        </label>
        <input type="text" class="form-control form-control-sm" formControlName="numeroEndereco"
          [ngClass]="formReativo.definindoClasseValidator(formEnderecoFaturamento, 'numeroEndereco')"
          (change)="emitirEnderecoFaturamento()" id="numeroEndereco">
        @if(controls['numeroEndereco']?.errors && controls['numeroEndereco']?.dirty){
        <small class="text-danger">*Campo obrigatório.</small>
        }
      </div>
      <div class="col-6 col-md-4">
        <label class="form-label">
          Bairro:
        </label>
        <input type="text" class="form-control form-control-sm" formControlName="bairro"
          (change)="emitirEnderecoFaturamento()">
      </div>
      <div class="col-6 col-md-3 col-lg-4">
        <label class="form-label">
          Cidade:
        </label>
        <input type="text" class="form-control form-control-sm" formControlName="cidade"
          (change)="emitirEnderecoFaturamento()">
      </div>
      <div class="col-6 col-md-3 col-lg-4">
        <label class="form-label">
          Estado:
        </label>
        <input type="text" class="form-control form-control-sm" formControlName="estado"
          (change)="emitirEnderecoFaturamento()">
      </div>
    </div>
  </form>
</section>