<app-loader></app-loader>
@if(isDesktop){
<app-nav-bar [Menuoption]="Menuoption" [Settingicon]="Settingicon" [linkRedirecionamento]="linkRedirecionamento">
</app-nav-bar>
<main id="main">
  <!-- Aqui serão renderizadas as páginas filhas -->
  <router-outlet class="container" (activate)="onActivate($event)"></router-outlet>
  <!-- Aqui serão renderizadas as páginas filhas -->
</main>
<app-footer [hideFooter]="hideFooter" [footerVariant]="footerVariant"></app-footer>
}@else {
<app-nav-bar [Menuoption]="Menuoption" [Settingicon]="Settingicon" [linkRedirecionamento]="linkRedirecionamento">
</app-nav-bar>
<router-outlet class="container" (activate)="onActivate($event)"></router-outlet>
<app-footer [hideFooter]="hideFooter" [footerVariant]="footerVariant"></app-footer>
@if(Menuoption !== 'contratar' && Menuoption !== 'contratar-nao-permitido-acesso-a-outros-menus'){
<section class="bg-home min-vh-100 d-flex align-items-center" style="background-color: #d0dbff;">
  <div class="container">
    <h1 class="text-center">Desculpe! Não é possível acessar esta ferramenta, por este dispositivo.</h1>
    <div class="text-center">
      <button type="button" class="btn btn-primary btn-sm" routerLink="/home">Voltar à página inicial</button>
    </div>
  </div>
</section>
}
}