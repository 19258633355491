<div class="row my-4">
  <div class="col-12 text-center">
    <div class="section-title mb-4 py-2">
      <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Quer mais?</h4>
      <p class="text-muted para-desc mx-auto mb-0" data-aos="fade-up" data-aos-duration="1400">Nosso SaaS
        <span class="text-primary fw-bold">extratoOFX</span> tem o compromisso de atender mais por menos. Caso não
        fique satisfeito com a nossa ferramenta, após os primeiros 30 dias,
        devolvemos seu dinheiro! E, vamos além, você pode cancelar quando quiser!
      </p>
    </div>
  </div>
