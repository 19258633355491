import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NovaContaParceiroV1Model } from '../models/nova-conta-parceiro-v1.model';

@Component({
  selector: 'app-form-novo-parceiro',
  templateUrl: './form-novo-parceiro.component.html',
  styles: ``
})
export class FormNovoParceiroComponent {
  //variáveis navbar
  Settingicon = false;
  Menuoption = 'parceiro';
  linkRedirecionamento = '/home';

  //variáveis footer
  hideFooter = true;

  //variáveis de ambiente
  formParceiro: FormGroup;

  constructor(
    private fb: FormBuilder,
    
  ) {
    this.formParceiro = fb.group({});
  }

  onSubmit(){
    // const novaContaParceiro: NovaContaParceiroV1Model;
  }
}
