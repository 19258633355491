import { Component, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationComponent } from 'src/app/auth/registration/registration.component';

@Component({
  selector: 'app-solucoes-simples-para-necessidades-extremas',
  templateUrl: './solucoes-simples-para-necessidades-extremas.component.html',
  styleUrl: '../../home/index.component.css'
})
export class SolucoesSimplesParaNecessidadesExtremasComponent {
  //injeções de dependência
  modalService = inject(NgbModal)

  //variáveis identificacao de dispositivo
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktop: boolean = false;

  constructor() {
    //Setando o tipo de dispositivo
    this.isMobile = window.innerWidth < 768;
    this.isTablet = (window.innerWidth >= 768 && window.innerWidth < 992);
    this.isDesktop = window.innerWidth >= 992;
  }

  abriModalQueroTestar() {
    let modalRef = this.modalService.open(
      RegistrationComponent,
      {
        modalDialogClass: 'modal-dialog',
        size: 'md',
        scrollable: true,
        centered: true,
        backdrop: 'static',
        keyboard: false,
      }
    )

    modalRef.componentInstance.planoSelecionado = undefined;
  }
}
