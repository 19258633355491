// Components do sistema
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
// Services
import { LoginService } from "src/app/auth/services/login.service";
// Utils
import { SecurityUtil } from '../utils/security.util';
import { ResultV1Model } from "src/app/shared/models/result-v1.model";
import { TratamentoErrosHttpErrorResponseService } from "src/app/shared/services/tratamento-erros-http-error-response.service";


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private route: Router,
    private loginService: LoginService,
  ) { }

  // verificar quanto tempo tem desde o ultimo refreshtoken
  refreshtoken() {
    this.loginService.refreshtoken().subscribe({
        next: (resultado: ResultV1Model) => {
          SecurityUtil.setToken(resultado.data.token);
          this.updateNotificacoes();
        },
        error: (err) => {
          TratamentoErrosHttpErrorResponseService.tratarErro(err);
          localStorage.clear();
          this.route.navigate(['/home']);
        }
      });
  }

  userLogado() {

  }

  updateNotificacoes() {

  }
}
