<!-- TAGLINE START-->
@if(sliderTopbar == true){
<div class="tagline bg-light">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="text-slider">
          <ul class="list-inline mb-0 move-text">
            <li class="list-inline-item px-2 mb-0"><b>EOG</b> $ 55.88 <span class="text-success">+$ 4.62 ( +9.01%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>CHKP</b> $ 120.03 <span class="text-danger">-$ 14.07 ( -10.49%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>FANG</b> $ 63.58 <span class="text-success">+$ 5.17 ( +8.84%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>M</b> $ 14.75 <span class="text-success">+$ 1.05 ( +7.66% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>PKI</b> $ 139.72 <span class="text-danger">-$ 11.41 ( -7.55%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ABMD</b> $ 326.23 <span class="text-danger">-$ 21.61 ( -6.21%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN</b> $ 37,471.47 <span class="text-danger">+$ 492.60 (
                +1.33% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>XRP</b> <span> $ 0.39</span><span class="text-muted"> UNCH</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>LITECOIN</b> <span> $ 148.67</span><span class="text-danger">-$
                5.58 ( -3.62% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN CASH</b> <span> $ 427.37</span><span
                class="text-danger">-$ 15.98 ( -3.60% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ETHEREUM</b> $ 1,647.87 <span class="text-danger">+$ 14.51 (
                +0.89% )</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
}
<!-- TAGLINE END-->

<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" [routerLink]="linkRedirecionamento">
        <img src="assets/imgs/extratoofx-color.png" height="35" alt="" class="l-light logo-light-mode">
      </a>
    </div>
    <!--end login button-->



    <!--Login button Start-->
    @if(Settingicon == true){
    <ul class="navigation-menu buy-button list-inline mb-0">
      <li class="has-submenu">
        <a href="javascript:void(0)" (click)="onMenuClick($event)">
          <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn">
            <i-feather name="settings" class="fea icon-sm"></i-feather>
          </div>
        </a>
        @if(Menuoption === 'conversor'){
        <ul class="submenu">
          <li>
            <ul>
              <li><a class="nav-link-ref" routerLink="/assinantes/meu-perfil">Meu Perfil</a></li>
              <li><a class="nav-link-ref" routerLink="/assinantes/financeiro">Financeiro</a></li>
              <li><a class="nav-link-ref" routerLink="/home">Sair</a></li>
            </ul>
          </li>
        </ul>
        }
      </li>
    </ul>
    }
    <!--Login button End-->

    <!-- Navigation Menu-->
    <div id="menu-navegacao">
      <ul class="navigation-menu">
        <li><a routerLink="/assinantes/conversor" class="nav-link-ref">Home</a></li>
        @if(Menuoption == 'conversor'){
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Converter</a>
          <span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <ul>
                <li><a class="nav-link-ref" routerLink="/assinantes/conversor/realizar-conversao/pdf-para-ofx">PDF para OFX</a></li>
                <li><a class="nav-link-ref" routerLink="/assinantes/conversor/realizar-conversao/excel-para-ofx">Excel para OFX</a></li>
                <li><a class="nav-link-ref" routerLink="/assinantes/conversor/realizar-conversao/csv-para-ofx">CSV para OFX</a></li>
              </ul>
            </li>
          </ul>
        </li>
        }
      </ul>
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->