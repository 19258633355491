<section id="dados-cartao">
  <div class="d-flex justify-content-end">
    <a [href]="helpLink" target="_blank" ngbTooltip="Clique para obter ajuda"
    placement="right" style="color: #007DB9;">
    <i-feather name="help-circle" class="fea icon-sm icons cursor-pointer"></i-feather>
  </a>
  </div>
  <form [formGroup]="formDadosCartao">
    <div class="row g-3">
      <div class="col-12 col-md-6">
        <label class="form-label">Titular do Cartão: <b class="text-danger">*</b></label>
        <input type="text" class="form-control form-control-sm" formControlName="nomeTitular" id="nomeTitular"
          [ngClass]="formReativo.definindoClasseValidator(formDadosCartao, 'nomeTitular')" (keyup)="emitirDadosCartao()"
          autocomplete="off" autofocus>
        @if(nomeUsuario!){
        <div class="form-check mt-1 mb-0 form-check-primary">
          <input type="checkbox" name="utilizar-mesmo-nome" id="utilizar-mesmo-nome" [(ngModel)]="isUtilizarMesmoNome"
            (ngModelChange)="utilizarMesmoNome()" [ngModelOptions]="{standalone: true}" (keyup)="emitirDadosCartao()"
            class="form-check-input cursor-pointer" autocomplete="off">
          <label for="utilizar-mesmo-nome" class="form-check-label cursor-pointer">
            Utilizar o mesmo nome?
          </label>
        </div>
        }
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label">CPF: <b class="text-danger">*</b></label>
        <input type="text" class="form-control form-control-sm" formControlName="cpfCnpjTitular"
          (input)="masksUtils.maskCpf($event)" id="cpfCnpjTitular" (keyup)="proximoInput('cpfCnpjTitular', 11, 'email')"
          [ngClass]="formReativo.definindoClasseValidator(formDadosCartao, 'cpfCnpjTitular')" autocomplete="off">
        @if(controls['cpfCnpjTitular'].dirty){
        @if(controls['cpfCnpjTitular'].errors?.['required']){
        <small class="text-danger">*Campo obrigatório.</small>
        }
        }
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label">E-mail: <b class="text-danger">*</b></label>
        <input type="email" class="form-control form-control-sm" formControlName="emailTitular"
          [ngClass]="formReativo.definindoClasseValidator(formDadosCartao, 'emailTitular')"
          (keyup)="emitirDadosCartao()" id="email" autocomplete="off">
        @if(controls['emailTitular'].errors && controls['emailTitular'].dirty){
        <small class="text-danger">*Campo obrigatório.</small>
        }
        @if(emailUsuario!){
        <div class="form-check mt-1 mb-0 form-check-primary">
          <input type="checkbox" name="utilizar-mesmo-email" id="utilizar-mesmo-email"
            [(ngModel)]="isUtilizarMesmoEmail" (ngModelChange)="utilizarMesmoEmail()"
            [ngModelOptions]="{standalone: true}" class="form-check-input cursor-pointer">
          <label for="utilizar-mesmo-email" class="form-check-label cursor-pointer">
            Mesmo email de acesso?
          </label>
        </div>
        }
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label">
          Telefone Celular: <b class="text-danger">*</b>
        </label>
        <input type="text" class="form-control form-control-sm" formControlName="celular" id="celular"
          (input)="masksUtils.maskPhone($event)" pattern="\d{11}" (keyup)="emitirDadosCartao()"
          [ngClass]="formReativo.definindoClasseValidator(formDadosCartao, 'celular')" autocomplete="off">
        @if(controls['celular'].dirty){
        @if(controls['celular'].errors?.['required']){
        <small class="text-danger">*Campo obrigatório.</small>
        }@else if (controls['celular'].errors?.['minlength'] || controls['celular'].errors?.['maxlength']) {
        <small class="text-danger">
          *Informe um número válido. Dica: verifique se o 9º dígito foi informado.
        </small>
        }
        }
        @if(telefoneUsuario!){
        <div class="form-check mt-1 mb-0 form-check-primary">
          <input type="checkbox" name="utilizar-mesmo-celular" id="utilizar-mesmo-celular"
            [(ngModel)]="isUtilizarMesmoCelular" (ngModelChange)="utilizarMesmoCelular()"
            [ngModelOptions]="{standalone: true}" (keyup)="emitirDadosCartao()" class="form-check-input cursor-pointer"
            autocomplete="off">
          <label for="utilizar-mesmo-celular" class="form-check-label cursor-pointer">
            Utilizar o mesmo celular?
          </label>
        </div>
        }
      </div>
    </div>

    <div ngbAccordion class="my-3" [closeOthers]="true">
      <div ngbAccordionItem [collapsed]="false" [disabled]="isGerarLinkPagamento!">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton>Pagar por aqui</button>
        </h2>
        <div ngbAccordionCollapse>
          @if(isGerarLinkPagamento == false){
          <div ngbAccordionBody>
            <div class="row g-3">
              <small class="text-muted pb-2 text-danger" role="alert">
                Você está realizando o pagamento pela extratoofx, em conjunto com a nossa parceira ASAAS, em um ambiente
                seguro.
              </small>
              <div class="col-12 col-md-5 col-lg-6">
                <label class="form-label">Número do cartão: <b class="text-danger">*</b></label>
                <input type="text" class="form-control form-control-sm" formControlName="numeroCartao" pattern="\d{16}"
                  (input)="masksUtils.maskNumeroCartao($event)" maxlength="16" (keyup)="emitirDadosCartao()"
                  [ngClass]="formReativo.definindoClasseValidator(formDadosCartao, 'numeroCartao')" id="numeroCartao"
                  (keyup)="proximoInput('numeroCartao', 16, 'cvv')" autocomplete="off">
                @if(controls['numeroCartao'].dirty && controls['numeroCartao'].touched){
                @if(controls['numeroCartao'].errors?.['minlength'] ||
                controls['numeroCartao'].errors?.['maxlength']){
                <small class="text-danger">*Informe um cartão válido.</small>
                }
                }
              </div>
              <div class="col-5 col-md-3 col-lg-2">
                <label class="form-label">CVV: <b class="text-danger">*</b></label>
                <input type="text" class="form-control form-control-sm" formControlName="cvv" pattern="\d{3}"
                  maxlength="3" [ngClass]="formReativo.definindoClasseValidator(formDadosCartao, 'cvv')"
                  (keyup)="emitirDadosCartao()" id="cvv" (keyup)="proximoInput('cvv', 3, 'validade')"
                  autocomplete="off">
                @if(controls['cvv'].dirty){
                @if(controls['cvv'].errors?.['required']){
                <small class="text-danger">*Campo obrigatório.</small>
                }
                }
              </div>
              <div class="col-7 col-md-4 col-lg-4">
                <label class="form-label">Validade: <b class="text-danger">*</b></label>
                <input type="text" class="form-control form-control-sm" formControlName="validade" pattern="\d{6}"
                  (input)="masksUtils.maskMesAno($event)" maxlength="6" (keyup)="emitirDadosCartao()"
                  [ngClass]="formReativo.definindoClasseValidator(formDadosCartao, 'validade')" id="validade"
                  autocomplete="off" placeholder="MM/AAAA">
                @if(controls['validade'].dirty){
                @if(controls['validade'].errors?.['required']){
                <small class="text-danger">*Campo obrigatório.</small>
                }@else if (controls['validade'].errors?.['notEqual']){
                <small class="text-danger">*Necessário que a validade seja maior que o mês atual.</small>
                }
                }
              </div>
            </div>
          </div>
          }
        </div>
      </div>
      <div ngbAccordionItem>
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton>
            <span>Link de Pagamento</span>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <p>
              Optando por receber o link de pagamento, ao finalizar o preenchimento dos campos necessários neste
              formulário, você receberá um email da ASAAS, nossa gestora de cobrança, para finalizar o pagamento da
              assinatura.
            </p>
            <div class="form-check mt-1 form-check-primary">
              <input type="checkbox" name="receber-link-pagamento" id="receber-link-pagamento"
                [(ngModel)]="isGerarLinkPagamento" [ngModelOptions]="{standalone: true}"
                class="form-check-input cursor-pointer" (ngModelChange)="onChangeGerarLinkPagamento()">
              <label for="receber-link-pagamento" class="form-check-label cursor-pointer">
                Desejo receber o link de pagamento
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-end">
        <a class="link cursor-pointer text-decoration-underline" (click)="emitirNF()">Emitir Nota Fiscal?</a>
      </div>
      @if(isEmitirNf){
      <caption class="text-nowrap mt-0">Dados Emissão NF</caption>
      <app-form-emissao-nf [emailUsuario]="emailUsuario"></app-form-emissao-nf>
      }
    </div>
  </form>
</section>