import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationComponent } from 'src/app/auth/registration/registration.component';

@Component({
  selector: 'app-quero-fazer-teste',
  templateUrl: './quero-fazer-teste.component.html',
})
export class QueroFazerTesteComponent {

  constructor(
    private modalService: NgbModal,
  ) { }

  abrirModalQueroTestar() {
    let modalRef = this.modalService.open(
      RegistrationComponent,
      {
        modalDialogClass: 'modal-dialog',
        size: 'md',
        scrollable: true,
        centered: true,
        backdrop: 'static',
        keyboard: true,
      }
    )

    modalRef.componentInstance.planoSelecionado = undefined;
  }
}
