import { Component } from '@angular/core';
import { PrecosComponent } from 'src/app/pages/components/precos/precos.component';

@Component({
  selector: 'app-renovar-contratacao',
  templateUrl: './renovar-contratacao.component.html',
  styles: ``
})
export class RenovarContratacaoComponent{
  //Variáveis de ambiente
  hasPlanoSelecionado: boolean = false;

  //Variáveis do Navbar
  Menuoption: string = 'contratar-nao-permitido-acesso-a-outros-menus';
  Settingicon: boolean = false;

  //Variáveis do Footer
  hideFooter: boolean = true;

  //Variáveis do PageTitle
  breadcrumbItems!: Array<{
    active?: boolean;
    label?: string;
    linkRedirecionamento?: string;
  }>;
  title = 'Contratar Assinatura';

  constructor() {
    this.breadcrumbItems = [
      { active: false, label: 'Conversor' },
      { active: true, label: 'Contratar' },
    ];

    PrecosComponent.planoSelecionadoAutenticadoSubject.subscribe(plano =>{
      if(plano!){
        this.hasPlanoSelecionado = true;
      }else{
        this.hasPlanoSelecionado = false;
      }
    })
  }
}
