import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from "../shared/shared.module";

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';

// Apex chart

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/index.component';
import { QueroFazerTesteComponent } from './components/quero-fazer-teste/quero-fazer-teste.component';
import { SolucoesSimplesParaNecessidadesExtremasComponent } from './components/solucoes-simples-para-necessidades-extremas/solucoes-simples-para-necessidades-extremas.component';
import { PagesRoutingModule } from './pages.routing';
import { CasosDeSucessoComponent } from './components/casos-de-sucesso/casos-de-sucesso.component';
import { ComoFuncionaComponent } from './components/como-funciona/como-funciona.component';
import { QueroMaisVantagensComponent } from './components/quero-mais-vantagens/quero-mais-vantagens.component';
import { PrecosComponent } from './components/precos/precos.component';
import { ClientsLogoComponent } from './components/clients-logo/clients-logo.component';
import { CommonModule } from '@angular/common';
import { LogoBancosComponent } from './components/logo-bancos/logo-bancos.component';
import { ConversoesDisponiveisComponent } from './components/conversoes-disponiveis/conversoes-disponiveis.component';


@NgModule({
  declarations: [
    /** Components PAGES */
    HomeComponent,
    ComoFuncionaComponent,
    SolucoesSimplesParaNecessidadesExtremasComponent,
    QueroFazerTesteComponent,
    CasosDeSucessoComponent,
    QueroMaisVantagensComponent,
    ClientsLogoComponent,
    LogoBancosComponent,
    ConversoesDisponiveisComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    RouterModule,
    FeatherModule,
    NgbDropdownModule,
    NgbModule,
    NgbNavModule,
    NgbTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PrecosComponent,
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
  ]
})
export class PagesModule { }
