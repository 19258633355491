<div class="container">
  <div class="rounded">
    <div class="row align-items-center">
      <div class="col-sm-7">
        <div class="text-sm-left">
          <h5 class="mb-0">Quero fazer um teste e saber se me atende.</h5>
        </div>
      </div>
      <!--end col-->

      <div class="col-sm-5 mt-4 mt-sm-0">
        <div class="text-sm-end">
          <a href="javascript:void(0)" (click)="abrirModalQueroTestar()" class="btn btn-outline-primary">Acesso
            livre por<span class="badge rounded-pill bg-danger ms-2">24h</span>
          </a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row -->
  </div>
</div>
<!--end container-->
