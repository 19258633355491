import { Component, Input, OnInit } from '@angular/core';
import { PlansV2Service } from '../../services/plans-v2.service';
import { PlansV2Model } from '../../models/plans-v2.model';
import { CouponsV1Model } from '../../models/coupons-v1.model';
import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalInserirCupomComponent } from './modal-inserir-cupom/modal-inserir-cupom.component';
import { PlanoSelecionadoV1Model } from '../../models/plano-selecionado-v1.model';
import { RegistrationComponent } from 'src/app/auth/registration/registration.component';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-precos',
  templateUrl: './precos.component.html',
  styleUrl: './precos.component.css',
  standalone: true,
  imports: [
    CommonModule,
    FeatherModule,
    NgbTooltipModule
  ]
})
export class PrecosComponent implements OnInit {
  //variáveis de entrada
  @Input() isAutenticado: boolean;
  @Input() isAcessoExpirado: boolean;
  @Input() isAlterarPlano: boolean;

  /**Plano selecionado pelo usuário que será enviado ao criar a nova conta e ser direcionado para o FinalizarCadastro */
  planoSelecionado?: PlanoSelecionadoV1Model = {
    planoSelecionado: null,
    isCupomAplicado: null,
    planoObject: null,
    cupomAplicado: null,
    valorFinal: null,
    planoVigente: null,
    isUsuarioAutenticado: null
  };

  //variáveis de ambiente
  planos!: PlansV2Model;
  planosCard: PlanosCardV1Model[] = [];
  cupom: CouponsV1Model
  isDescontoAplicado: boolean = false;
  isDesktop: boolean;
  helpLink: string = 'https://doc.extratoofx.com/docs/tutorial-utilizando/planos#escolha-o-plano-que-melhor-atende-suas-necessidades'

  static planoSelecionadoAutenticadoSubject = new Subject<PlanoSelecionadoV1Model>();

  constructor(
    private plansV2Service: PlansV2Service,
    private modalService: NgbModal,
  ) { 
    this.isDesktop = window.innerWidth >= 1024;
  }

  ngOnInit(): void {
    this.getPlanos();
  }

  assinar(planoSelecionado: PlanosCardV1Model) {
    const opcao = planoSelecionado.titulo.trim();

    if (!this.isAutenticado && !this.isAcessoExpirado) {
      const modalRef = this.modalService.open(
        RegistrationComponent,
        { size: 'md', centered: true }
      )

      modalRef.componentInstance.planoSelecionado = opcao;
    }

    switch (opcao) {
      case 'Mensal':
        this.planoSelecionado.planoSelecionado = '1';
        break;
      case 'Trimestral':
        this.planoSelecionado.planoSelecionado = '3';
        break;
      case 'Semestral':
        this.planoSelecionado.planoSelecionado = '6';
        break;
      default:
        break;
    }

    /**Completando o objeto com as informações referente à assinatura que o usuário escolheu para contratar */
    this.planoSelecionado.planoObject = {
      titulo: planoSelecionado.titulo,
      desconto: planoSelecionado.desconto,
      isDesc: planoSelecionado.isDesc,
      list: planoSelecionado.list,
      melhorOpcao: planoSelecionado?.melhorOpcao,
      valor: this.formantandoStringParaNumber(planoSelecionado.valor)
    };

    if (this.planoSelecionado.isCupomAplicado) {
      this.planoSelecionado.valorFinal = this.formantandoStringParaNumber(planoSelecionado.valorTotalComCupom);
    } else {
      const valorSemDesconto = this.formantandoStringParaNumber(planoSelecionado.valor);
      this.planoSelecionado.valorFinal = valorSemDesconto;
    }

    /**
    * Salvando o objeto com as informações referente à assinatura que o usuário escolheu para contratar 
    * No Armazenamento por Sessão (SessionStorage)
    */
    this.setPlanoSelecionadoSessionStorage(this.planoSelecionado);

    /**
     * Se o usuário estiver logado na plataforma, e selecionar a opção 'contratar'
     * ele emite o planoSelecionado informando que foi setado um plano, no sessionStorage 
     */
    if (this.isAutenticado || this.isAcessoExpirado) {
      PrecosComponent.planoSelecionadoAutenticadoSubject.next(this.planoSelecionado);
    }
  }

  abrirModalInserirCupom() {
    const modalRef = this.modalService.open(
      ModalInserirCupomComponent,
      {
        size: 'sm',
        centered: true
      }
    );

    //Escutando a variável resultadoCupom do componente ModalInserirCupom
    modalRef.componentInstance.resultadoCupom.subscribe(resultado => {
      const cupomAplicado: CouponsV1Model = resultado.data;
      const valorDescontoPorMes: number = cupomAplicado.valor;
      this.isDescontoAplicado = true;
      this.planoSelecionado.isCupomAplicado = true;
      this.planoSelecionado.cupomAplicado = cupomAplicado;
      this.planosCard.forEach(plano => {
        if (plano.titulo.includes('Mensal')) {
          plano.isCupomAplicado = true;
          plano.descontoCupom = this.formatandoNumberParaStringEmReal(valorDescontoPorMes);
          plano.valorTotalComCupom = this.formatandoNumberParaStringEmReal(this.formantandoStringParaNumber(plano.valor) - valorDescontoPorMes);
        } else if (plano.titulo.includes('Trimestral')) {
          plano.isCupomAplicado = true;
          plano.descontoCupom = this.formatandoNumberParaStringEmReal((valorDescontoPorMes * 3));
          plano.valorTotalComCupom = this.formatandoNumberParaStringEmReal(this.formantandoStringParaNumber(plano.valor) - valorDescontoPorMes * 3);
        } else if (plano.titulo.includes('Semestral')) {
          plano.isCupomAplicado = true;
          plano.descontoCupom = this.formatandoNumberParaStringEmReal((valorDescontoPorMes * 6));
          plano.valorTotalComCupom = this.formatandoNumberParaStringEmReal(this.formantandoStringParaNumber(plano.valor) - valorDescontoPorMes * 6);
        }
      })
    })
  }

  removerCupom(){
    this.isDescontoAplicado = false;
    this.planoSelecionado.isCupomAplicado = false;
    this.planoSelecionado.cupomAplicado = null;
    this.planoSelecionado.valorFinal = this.planoSelecionado?.planoObject?.valor;
  }

  async getPlanos() {
    await this.plansV2Service.getPlansDefault().subscribe({
      next: resultado => {
        if (resultado.success) {
          this.planos = resultado.data;
          this.planoSelecionado.planoVigente = resultado.data
          this.planosCard = [
            {
              titulo: `${this.planos.planoMensal.titulo} `,
              /**aplicando 6 porcento no valor, que refere-se aos impostos */
              valor: `${this.formatandoNumberParaStringEmReal(this.planos.planoMensal.valor * 1.06)} `,
              isDesc: this.planos.planoMensal.isDesc,
              desconto: `${this.planos.planoMensal.desconto} `,
              list: this.planos.planoMensal.list,
              btn: (this.isAutenticado || this.isAcessoExpirado) ? "Quero este plano" : "Iniciar agora!",
            },
            {
              melhorOpcao: `${this.planos.planoTrimestral.melhorOpcao} `,
              titulo: `${this.planos.planoTrimestral.titulo} `,
              valor: `${this.formatandoNumberParaStringEmReal(this.planos.planoTrimestral.valor * 1.06)} `,
              isDesc: this.planos.planoTrimestral.isDesc,
              desconto: `${this.planos.planoTrimestral.desconto} `,
              list: this.planos.planoTrimestral.list,
              btn: (this.isAutenticado || this.isAcessoExpirado) ? "Quero este plano" : "Iniciar agora!",
            },
            {
              titulo: `${this.planos.planoSemestral.titulo} `,
              valor: `${this.formatandoNumberParaStringEmReal(this.planos.planoSemestral.valor * 1.06)} `,
              isDesc: this.planos.planoSemestral.isDesc,
              desconto: `${this.planos.planoSemestral.desconto} `,
              list: this.planos.planoSemestral.list,
              btn: (this.isAutenticado || this.isAcessoExpirado) ? "Quero este plano" : "Iniciar agora!",
            },
          ];
        }
      },
      error: err => {
        TratamentoErrosHttpErrorResponseService.tratarErro(err);
        this.planosCard = [];
      }
    });
  }

  formatandoNumberParaStringEmReal(valor: number) {
    const valorSplitted = valor.toFixed(2).toString().split(".");
    const valorEmReal = `${valorSplitted[0]},${valorSplitted[1]}`
    return valorEmReal;
  }

  formantandoStringParaNumber(valor: string) {
    return parseFloat(valor.replace('.', '').replace(',', '.'));
  }

  //Funções que setam o planoSelecionado pelo usuário no SessionStorage
  setPlanoSelecionadoSessionStorage(plano: PlanoSelecionadoV1Model) {
    sessionStorage.setItem('planoSelecionado', btoa(JSON.stringify(plano)));
  };

  static getPlanoSelecionadoSessionStorage(): PlanoSelecionadoV1Model {
    return JSON.parse(atob(sessionStorage.getItem('planoSelecionado')));
  }

  static hasPlanoSelecionado() {
    return sessionStorage.getItem('planoSelecionado') ? true : false;
  }
  //----------------------------------------------------------------

}

interface PlanosCardV1Model {
  titulo: string,
  valor: string,
  isDesc: boolean,
  desconto: string,
  list: string[],
  btn: string,
  melhorOpcao?: string,
  isCupomAplicado?: boolean,
  descontoCupom?: string,
  valorTotalComCupom?: string,
}