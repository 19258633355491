import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountV1Model } from 'src/app/auth/models/account-v1.model';
import { PrecosComponent } from 'src/app/pages/components/precos/precos.component';
import { PlanoSelecionadoV1Model } from 'src/app/pages/models/plano-selecionado-v1.model';
import { DetalhesAssinaturaComponent } from './detalhes-assinatura/detalhes-assinatura.component';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { MasksUtil } from 'src/app/shared/services/masks.util';
import { FeatherModule } from 'angular-feather';
import { FormDadosCartaoComponent } from './form-dados-cartao/form-dados-cartao.component';
import { NovaAssinaturaV1Model } from '../models/nova-assinatura-v1.model';
import { distinctUntilChanged } from 'rxjs';
import { FormEnderecoFaturamentoComponent } from './form-endereco-faturamento/form-endereco-faturamento.component';
import { FormEmissaoNfComponent } from './form-emissao-nf/form-emissao-nf.component';
import { GetIpAddressService } from 'src/app/shared/services/get-ip-address.service';
import { ComunicacaoApiAssinaturaV1Service } from '../services/comunicacao-api-assinatura-v1.service';
import { LoginService } from 'src/app/auth/services/login.service';
import Swal from 'sweetalert2';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import { Router } from '@angular/router';
import { SecurityUtil } from 'src/app/_core/utils/security.util';
import { SplitsV1Model } from 'src/app/pages/models/splits-v1.model';
import { PaymentSaasV1Model } from 'src/app/pages/models/payment-saas-v1.model';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { ModalSelecionarFormaDeRealizarOPagamentoComponent } from './modal-selecionar-forma-de-realizar-o-pagamento/modal-selecionar-forma-de-realizar-o-pagamento.component';
import { FormDadosTitularComponent } from './form-dados-titular/form-dados-titular.component';
import { ConfiguracoesPadraoNF } from './data/configuracoes-padrao-nf';

@Component({
  selector: 'app-form-contratar-assinatura',
  standalone: true,

  imports: [
    CommonModule,
    DetalhesAssinaturaComponent,
    FormDadosCartaoComponent,
    FormDadosTitularComponent,
    FormEnderecoFaturamentoComponent,
    FormEmissaoNfComponent,
    ModalSelecionarFormaDeRealizarOPagamentoComponent,
    FeatherModule
  ],
  templateUrl: './form-contratar-assinatura.component.html',
  styleUrl: './form-contratar-assinatura.component.css'
})
export class FormContratarAssinaturaComponent implements OnInit, OnDestroy {
  //variáveis de entrada
  @Input() isAlterarPlano?: boolean;

  //variáveis de ambiente
  isUsuarioNaoAutenticado: boolean;
  hasPlanoSelecionado: boolean = false;
  userAccount: AccountV1Model;
  planoSelecionado?: PlanoSelecionadoV1Model;
  isGerarLinkPagamento?: boolean;
  isCepValido: boolean = false;
  isDadosCartaoPreenchido: boolean = false;
  isDadosTitularPreenchido: boolean = false;
  isEnderecoFaturamentoPreenchido: boolean = false;
  novaAssinatura: NovaAssinaturaV1Model = {
    _idAccount: null,
    paymentSaas: {
      cupom: null,
      isCupom: null,
      opcao: null,
      payment: {
        cicloEscolhido: null,
        splitDefinido: null,
        valorACobrar: null
      },
      planos: {
        _id: null,
        ativo: null,
        dtDesativado: null,
        excluded: null,
        planoMensal: null,
        planoSemestral: null,
        planoTrimestral: null,
        split: null
      },
    },
    signature: {
      nextDueDate: null,
      split: null,
      value: null,
      billingType: null,
      creditCard: {
        ccv: null,
        expiryMonth: null,
        expiryYear: null,
        holderName: null,
        number: null,
      },
      creditCardHolderInfo: {
        addressNumber: null,
        cpfCnpj: null,
        email: null,
        mobilePhone: null,
        name: null,
        postalCode: null,
      },
      remoteIp: ''
    },
    isNf: false,
    customer: {
      addressNumber: null,
      cnpj: null,
      email: null,
      mobilePhone: null,
      name: null,
      postalCode: null,
    },
    configNf: {
      municipalServiceId: null,
      municipalServiceCode: null,
      municipalServiceName: null,
      deductions: null,
      effectiveDatePeriod: null,
      daysBeforeDueDate: null,
      receivedOnly: null,
      observations: null,
      taxes: {
        retainIss: null,
        cofins: null,
        csll: null,
        inss: null,
        ir: null,
        pis: null,
        iss: null
      }
    }
  };

  constructor(
    public formReativo: FormReativoService,
    private loaderService: LoaderService,
    public masksUtils: MasksUtil,
    private ipAddressService: GetIpAddressService,
    private readonly comunicacaoComApi: ComunicacaoApiAssinaturaV1Service,
    private readonly loginService: LoginService,
    private route: Router,
  ) {
    /**
     * Escutando o subject para obter os dados do cartão do component FormDadosCartao,
     * caso o usuário opte por realizar o pagamento na nossa plataforma
     */
    FormDadosCartaoComponent.dadosCartao.pipe(distinctUntilChanged()).subscribe(dados => {
      if (dados) {
        /**
         * Se o valor emitido pelo subject for um valor válido (diferente de null ou undefined),
         * significando que o usuário informou todos os dados solicitados do cartão de crédito e titular do cartão
         */
        this.isDadosCartaoPreenchido = true;

        if (dados.numeroCartao!) {
          /**Caso o usuário opte por pagar em nossa plataforma */
          this.novaAssinatura.signature.billingType = 'CREDIT_CARD'

          //Preenchendo o objeto novaAssinatura.signature.creditCard que será enviado para a api, com os dados informados do cartão
          this.novaAssinatura.signature.creditCard = {
            number: dados.numeroCartao,
            ccv: dados.cvv,
            expiryMonth: dados.validade.slice(0, 2),
            expiryYear: dados.validade.slice(2),
            holderName: dados.nomeTitular
          };

          //Preenchendo o objeto novaAssinatura.signature.creditCardHolderInfo que será enviado para a api, com os dados informados do titular do cartão
          this.novaAssinatura.signature.creditCardHolderInfo = {
            addressNumber: null,
            cpfCnpj: dados.cpfCnpj,
            mobilePhone: dados.celular,
            email: dados.emailTitular,
            name: dados.nomeTitular,
            postalCode: null
          };
          this.userAccount.cpf = dados.cpfUsuario! ? dados.cpfUsuario : '';
        } else {
          /**Caso o usuário opte por receber o link de pagamento */
          this.novaAssinatura.signature.billingType = 'UNDEFINED';

          //Preenchendo o objeto novaAssinatura.customer que será enviado para a api, com os dados informados do titular do cartão
          this.novaAssinatura.customer = {
            addressNumber: null,
            cpf: dados.cpfCnpj,
            cnpj: null,
            mobilePhone: dados.celular,
            email: dados.emailTitular,
            name: dados.nomeTitular,
            postalCode: null
          };

          /**
           * Garantindo que os dados do cartão não serão enviados, 
           * caso o usuário informe e depois decida que quer receber o link
           */
          this.novaAssinatura.signature.creditCard = {
            number: undefined,
            ccv: undefined,
            expiryMonth: undefined,
            expiryYear: undefined,
            holderName: undefined
          };

          this.novaAssinatura.signature.creditCardHolderInfo = {
            addressNumber: undefined,
            cpfCnpj: undefined,
            mobilePhone: undefined,
            email: undefined,
            name: undefined,
            postalCode: undefined
          };
        };
      }
    });

    /**Escutando o subject para obter os dados para emissão de NF do component FormEmissaoNF */
    FormEmissaoNfComponent.dadosEmissaoNF.pipe(distinctUntilChanged()).subscribe(dados => {
      if (dados!) {
        /**
         * Se o valor emitido pelo subject for um valor válido (diferente de null ou undefined),
         * significando que o usuário informou todos os dados solicitados para a emissão da nf
         */

        //O Account.dadosAccount é atualizado com os dados da empresa
        this.userAccount.dadosAccount.cnpjEscritorio = dados.cnpjEmpresa;
        this.userAccount.dadosAccount.emailEscritorio = dados.emailEmpresa;
        this.userAccount.dadosAccount.nomeEscritorio = dados.razaoSocial.toUpperCase();

        //O objeto da nova assinatura é atualizado com os dados da empresa, sendo o customer para emissão da nf
        this.novaAssinatura.customer = {
          cnpj: dados.cnpjEmpresa,
          cpf: null,
          name: dados.razaoSocial.toUpperCase(),
          email: dados.emailEmpresa,
          mobilePhone: this.novaAssinatura.customer.mobilePhone,
          addressNumber: null,
          postalCode: null,
        }

        //O obj novaAssinatura recebe os dados de configuração da nota fiscal
        this.novaAssinatura.configNf = ConfiguracoesPadraoNF;

        //Informa que será configurado a nota fiscal, para que a api trate devidamente o customer
        this.novaAssinatura.isNf = true;
      } else {
        /**
         * Se o dado retornado for undefined ou null, então significa que o usuário
         * não informou os dados para emissão da nf e o customer que será definido no asaas
         * será o titular do cartão informado.
          */
        this.novaAssinatura.isNf = false;
      }
    });

    /**Escutando o subject para obter o endereço de faturamento do component FormEnderecoFaturamento */
    FormEnderecoFaturamentoComponent.dadosEnderecoFaturamento.pipe(distinctUntilChanged()).subscribe(endereco => {
      /**
       * Se o valor emitido pelo subject for um valor válido (diferente de null ou undefined),
       * significando que o usuário informou todos os dados solicitados do endereço de faturamento
       */

      this.isEnderecoFaturamentoPreenchido = true;

      //Preenchendo o objeto novaAssinatura.signature nos atributos pertinentes ao endereço de faturamento
      this.novaAssinatura.signature.nextDueDate = this.definindoProximaCobranca();
      if (this.novaAssinatura.signature.creditCard.creditCardNumber!) {
        /**Se o usuário optou por pagar pela nossa plataforma */
        this.novaAssinatura.signature.creditCardHolderInfo.postalCode = endereco.cep;
        this.novaAssinatura.signature.creditCardHolderInfo.addressNumber = endereco.numero;
      } else {
        /**Se o usuário optou por receber o link de pagamento */
        this.novaAssinatura.customer.postalCode = endereco.cep;
        this.novaAssinatura.customer.addressNumber = endereco.numero;
      }
      this.userAccount.dadosAccount.isEndPadrao = false;
      this.userAccount.dadosAccount.endereco = {
        cep: endereco.cep,
        bairro: endereco.bairro,
        cidade: endereco.cidade,
        logradouro: endereco.logradouro,
        uf: endereco.uf,
        numero: endereco.numero
      }
    });

    PrecosComponent.planoSelecionadoAutenticadoSubject.pipe(distinctUntilChanged()).subscribe(plano => {
      this.planoSelecionado = plano;
    })
  }

  ngOnInit(): void {
    this.loaderService.startLoader(false);
    /**Obtendo o IP da máquina do usuário */
    this.getIpClient();

    setTimeout(() => {
      this.isUsuarioNaoAutenticado = !SecurityUtil.hasToken();
      if (sessionStorage.getItem('userAccount')) {
        this.definindoUserAccount();
      }
      if (PrecosComponent.hasPlanoSelecionado()) {
        this.hasPlanoSelecionado = true;
        this.planoSelecionado = PrecosComponent.getPlanoSelecionadoSessionStorage();
      }
      this.loaderService.stopLoader();
    }, 1000)
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('planoSelecionado');
  }

  avancarEtapa(etapaAtual: number) {
    //capturando os elementos da etapa 1
    const linkEl1 = document.getElementById('select-dados-cartao');
    const divEl1 = document.getElementById('dados-cartao');
    //capturando os elementos da etapa 2
    const linkEl2 = document.getElementById('select-endereco-faturamento');
    const divEl2 = document.getElementById('endereco-faturamento');
    //capturando os elementos da etapa 3
    const linkEl3 = document.getElementById('select-emissao-nf');
    const divEl3 = document.getElementById('dados-emissao-nf');

    switch (etapaAtual) {
      case 1:
        //removendo as classes e atributos para que o item da etapa 1 não fique visível na tela
        linkEl1.removeAttribute('aria-current');
        linkEl1.classList.toggle('active');
        divEl1.classList.toggle('active')
        divEl1.classList.toggle('show')

        //adicionando as classes necessárias para que o item da etapa 2 seja ativado
        linkEl2.classList.toggle('active');
        linkEl2.setAttribute('aria-current', 'true');
        divEl2.classList.toggle('active');
        divEl2.classList.toggle('show');
        break;
      case 2:
        //removendo as classes e atributos para que o item da etapa 2 não fique visível na tela
        linkEl2.removeAttribute('aria-current');
        linkEl2.classList.toggle('active');
        divEl2.classList.toggle('active')
        divEl2.classList.toggle('show')

        //adicionando as classes necessárias para que o item da etapa 2 seja ativado
        linkEl3.classList.toggle('active');
        linkEl3.setAttribute('aria-current', 'true');
        divEl3.classList.toggle('active');
        divEl3.classList.toggle('show');
        break;
      default:
        break;
    }
  }

  voltarEtapa(etapaAtual: number) {
    //capturando os elementos da etapa 1
    const linkEl1 = document.getElementById('select-dados-cartao');
    const divEl1 = document.getElementById('dados-cartao');
    //capturando os elemento da etapa 2, que é a atual
    const linkEl2 = document.getElementById('select-endereco-faturamento');
    const divEl2 = document.getElementById('endereco-faturamento');
    //capturando os elementos da etapa 3
    const linkEl3 = document.getElementById('select-emissao-nf');
    const divEl3 = document.getElementById('dados-emissao-nf');

    switch (etapaAtual) {
      case 2:
        //removendo as classes para que o item da etapa 2 não fique visível na tela
        linkEl2.classList.toggle('active');
        linkEl2.removeAttribute('aria-current');
        divEl2.classList.toggle('active')
        divEl2.classList.toggle('show')

        //adicionando as classes necessárias para que o item da etapa 1 seja ativado
        linkEl1.classList.toggle('active');
        linkEl1.setAttribute('aria-current', 'true');
        divEl1.classList.toggle('active');
        divEl1.classList.toggle('show');
        break;
      case 3:
        //removendo as classes para que o item da etapa 2 não fique visível na tela
        linkEl3.classList.toggle('active');
        linkEl3.removeAttribute('aria-current');
        divEl3.classList.toggle('active')
        divEl3.classList.toggle('show')

        //adicionando as classes necessárias para que o item da etapa 1 seja ativado
        linkEl2.classList.toggle('active');
        linkEl2.setAttribute('aria-current', 'true');
        divEl2.classList.toggle('active');
        divEl2.classList.toggle('show');
        break;
      default:
        break;
    }
  }

  formatandoNumberParaStringEmReal(valor: number) {
    const valorSplitted = valor.toFixed(2).toString().split(".");
    const valorEmReal = `${valorSplitted[0]},${valorSplitted[1]}`
    return valorEmReal;
  }

  /**
   * Função utilizada para calcular o valor do desconto, em caso de cupom aplicado
   * @returns o valor do desconto devidamente formatado em real (string)
   */
  definirDescontoRecebido() {
    const valorDesconto = this.planoSelecionado.cupomAplicado.valor * parseInt(this.planoSelecionado.planoSelecionado);
    return this.formatandoNumberParaStringEmReal(valorDesconto)
  }

  definindoUserAccount() {
    /**Capturando o userAccount setado no sessionStorage */
    this.userAccount = JSON.parse(atob(sessionStorage.getItem('userAccount')));
    /**Verificando se o obj dadosAccount existe no userAccont */
    if (!this.userAccount.dadosAccount) {
      /**Se não existir, ele será inicializado, para que possa ser setado, posteriormente */
      this.userAccount.dadosAccount = {
        cnpjEscritorio: null,
        emailEscritorio: null,
        isEndPadrao: null,
        nomeEscritorio: null,
        endereco: {
          bairro: null,
          cidade: null,
          logradouro: null,
          uf: null,
          cep: null,
          numero: null,
        }
      }
    };

    /**Verificando se o apelido atribuido no cadastro possui um length maior que 10 (10 é o máximo permitido na API) */
    if (this.userAccount._user.apelido.length > 10) {
      /**
       * Se o length for maior que 10
       */
      const apelido = this.userAccount._user.apelido;

      /**repartindo a cada espaço em branco */
      const apelidoSplitted = apelido.split(' ');

      /**Verificando se o elemento do primeiro indice[0] (primeiro nome) possui o length menor ou igual à 10  */
      if (apelidoSplitted[0].length <= 10) {
        /**Se tiver, o apelido será atualizado para o primeiro nome */
        this.userAccount._user.apelido = apelidoSplitted[0]
      } else {
        /**Se não tiver, o apelido será atualizado para o primeiro nome, cortando do indice 0 ao 9 (10 caracteres) */
        this.userAccount._user.apelido = apelidoSplitted[0].slice(0, 10)
      }
    }
  }

  async finalizarContratacao() {
    this.loaderService.startLoader(false);

    /**Preenchendo o obejto NovaAssinatura com os dados necessários */
    this.novaAssinatura.paymentSaas.payment = {
      cicloEscolhido: this.planoSelecionado.planoObject.titulo.toLowerCase().trim(),
      valorACobrar: this.planoSelecionado.valorFinal,
      splitDefinido: null
    }
    this.novaAssinatura.signature.value = this.planoSelecionado.valorFinal;

    /**
     * Verificando se foi aplicado algum cupom e setando os dados
     * de acordo com o resultado da verificação
     */
    if (this.planoSelecionado.isCupomAplicado!) {
      /**Se tiver cupom aplicado */
      this.novaAssinatura.paymentSaas.isCupom = true;
      this.novaAssinatura.paymentSaas.cupom = this.planoSelecionado.cupomAplicado;
      this.novaAssinatura.signature.split = this.ajustandoSplitParaSubscription(this.planoSelecionado.cupomAplicado.split)
      this.novaAssinatura.paymentSaas.payment.splitDefinido = this.planoSelecionado.cupomAplicado.split
    } else {
      /**Se não tiver cupom aplicado */
      this.novaAssinatura.signature.split = this.ajustandoSplitParaSubscription(this.planoSelecionado.planoVigente.split)
      this.novaAssinatura.paymentSaas.payment.splitDefinido = this.planoSelecionado.planoVigente.split
    }
    this.novaAssinatura.paymentSaas.planos = this.planoSelecionado.planoVigente;
    this.novaAssinatura.paymentSaas.opcao = this.planoSelecionado.planoObject.titulo;
    this.novaAssinatura.configNf.observations = `Referente à assinatura ${this.planoSelecionado.planoObject.titulo.trim().toLocaleLowerCase()} do Conversor de Extrato Bancário`
    /**Fim Preenchimento do objeto NovaAssinatura */

    /**
     * Verificando se trata-se de um usuário não autenticado na plataforma
     * sendo caso como assinatura/avaliação expirada(o)
     */
    if (this.isUsuarioNaoAutenticado) {
      /**
       * Se não tiver logado, será setado o idAccount que foi retornado
       * ao tentar logar na plataforma.
       * Obs: Se o usuário não estiver autenticado, e caso ele opte em receber a nota fiscal,
       * passando os dados da empresa, não será possível atualizar o account.dadosAccount deste usuário,
       * neste momento, uma vez que é necessário que o mesmo esteja logado na plataforma para isto (token).
       *
       * Nestes casos, será feito uma avaliação e atualização, quando o usuário acessar a opção 'Meu perfil', logado na plataforma.
       */
      this.novaAssinatura._idAccount = this.userAccount._idAccount;
    } else {
      /**Se o usuário estiver logado */
      try {
        /**É feito um update no account do usuário, pois durante o processo, alguns dados são alterados/adicionados */
        await this.loginService.updateAccount({
          _idAccount: this.userAccount._id,
          _idUser: this.userAccount._user._id,
          account: this.userAccount,
          user: this.userAccount._user
        });
      } catch (error) {
        /**Em caso de algum erro durante o processo de update do account */
        TratamentoErrosHttpErrorResponseService.tratarErro(error)
      }
    }

    /**Verificando se trata-se de uma altereção do plano ou uma nova assinatura */
    if (this.isAlterarPlano) {
      /**
       * Se for uma alteração do plano, será realizado uma exclusão do plano anterior
       * E seguirá para que seja feito o post de uma nova assinatura
       */
      const paymentSaas: PaymentSaasV1Model = JSON.parse(atob(sessionStorage.getItem('paymentSaas')))

      await this.comunicacaoComApi.deleteAssinatura({
        _idAccount: this.userAccount._idAccount,
        idSubscription: paymentSaas.subscription.id,
        isConfigNf: paymentSaas.invoices.length === 0 ? false : true
      });
    }

    /**Fazendo um post da nova assinatura */
    await this.comunicacaoComApi.postNovaAssinatura(this.novaAssinatura).subscribe({
      next: resultado => {
        if (resultado.success) {
          this.loaderService.stopLoader();
          Swal.fire({
            title: resultado.titulo,
            text: resultado.message + this.novaAssinatura.signature.billingType === 'UNDEFINED' ? '\nVocê receberá um email da ASAAS para finalizar o pagamento da assinatura.' : '',
            icon: 'success'
          }).then(() => {
            sessionStorage.removeItem('planoSelecionado');

            /**Verificando qual o tipo de solicitação originada */
            if (this.isAlterarPlano) {
              /**Se foi uma alteração de plano */
              sessionStorage.removeItem('paymentSaas');
              this.route.navigate(['/assinantes/meu-perfil']);
            } else if (this.isUsuarioNaoAutenticado) {
              /**Se foi uma renovação de contratação, onde o usuário já testou e retestou, com expiração da dataFimAvaliacao */
              this.route.navigate(['/home']);
            } else {
              /**
               * Se o usuário selecionou 'contratar' estando logado na plataforma, 
               * ou se o usuário optou por contratar o plano direto, sem criar a conta de avaliação primeiro.
               * (Isso ocorre porque, ao optar pela contratação do plano sem optar pelo teste grátis,
               * criamos uma conta de avaliação, para logar o usuário e ele finaliza a contratação em um ambiente
               * logado.)
               * 
               * Será feito uma atualização no userAccount que é setado no sessionStorage, 
               * para que as variáveis de avaliação do tipo de conta sejam atualizadas 
               * de acordo com o resultado do pagamento da assinatura 
               * e o usuário será redirecionado para o painel do conversor
               */
              this.getUserAccById();
              this.route.navigate(['/assinantes/conversor']);
            }
          });
        } else {
          this.loaderService.stopLoader();
          let message = '';
          /**
           * capturando a messagem de error, se o resultado.error typeof == array
           * capturando então o error.description
           */
          if (resultado.error!) {
            if (typeof (resultado.error) !== 'string') {
              resultado.error.forEach(element => {
                message += element.description + ' '
              });

            } else {
              message = resultado.error
            }
          } else {
            message = resultado.message
          }
          Swal.fire(
            'Algo deu errado!',
            message,
            'error'
          ).then(() => {
            /**Em caso de erro, a etapa Dados do Cartão será ativada novamente */
            this.voltarEtapa(2);
          });
        }
      },
      error: err => {
        this.loaderService.stopLoader();
        TratamentoErrosHttpErrorResponseService.tratarErro(err);
      }
    })
  }

  /**Função que define a próximaDataCobrança sendo atribuida ao nextDueDate */
  definindoProximaCobranca() {
    const dtHoje: Date = new Date();
    // const dia = dtHoje.getDate();
    const proxCobranca = dtHoje;

    /**
     * É adicionado um dia à data de hoje. Na API é que é adicionado a quantidade de meses,
     * de acordo com o ciclo de assinatura escolhido
     */
    // proxCobranca.setDate(dia + 1)

    return proxCobranca;

    /**
     * De acordo com a documentação da asaas, em casos de assinatura, a primeira cobrança é realizada no dia determinado em nextDueDate.
     * Como queremos que a cobrança seja imediata, estou passando a data de hoje como vencimento
     */
  }

  /**
   * Função utilizada para remover a classe show do elemento #off-canvas-detalhes-assinatura
   */
  removeShow() {
    const offcanvas = document.getElementById('off-canvas-detalhes-assinatura');
    offcanvas?.classList.remove('show');
  }

  /**Função utilizada para obter o ip do usuário */
  async getIpClient() {
    await this.ipAddressService
      .getIPAddress()
      .subscribe({
        next: (resultado: { ip: string }) => {
          this.novaAssinatura.signature.remoteIp = resultado.ip;
        }
      })
  }

  /**
   * Função utilizada para ajustar o array de splits para que fique igual ao
   * solicitado na API
   */
  ajustandoSplitParaSubscription(splits: SplitsV1Model[]): SplitSubscriptionV1Model[] {
    let splitsModificadosParaSubscription: SplitSubscriptionV1Model[] = [];
    splits.forEach(split => {
      const splitModificado: SplitSubscriptionV1Model = {
        walletId: split.walletId,
        fixedValue: split.valor
      }
      splitsModificadosParaSubscription.push(splitModificado)
    })
    return splitsModificadosParaSubscription;
  }

  /**Função utilizada para setar o Account do usuário no sessionStorage e emitir o valor no subject userLogado */
  private async getUserAccById() {
    await this.loginService.getContaById().subscribe({
      next: resultado => {
        sessionStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)))
        LoginComponent.userLogado.next(resultado.data)
      }
    })
  }
}

export interface SplitSubscriptionV1Model {
  /**
     * description: 'Identificador da carteira (retornado no momento da criação da conta)'
     */
  walletId: string;

  /**
   * description: 'Valor fixo a ser transferido para a conta quando a cobrança for recebida'
   */
  fixedValue?: number;

  /**
   * description: 'Percentual sobre o valor líquido da cobrança a ser transferido quando for recebida'
   */
  percentualValue?: number;

  /**
   * description: '(Somente parcelamentos). Valor que será feito split referente ao valor total que será parcelado.'
   */
  totalFixedValue?: number;
}
