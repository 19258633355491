import { CommonModule } from '@angular/common';
import { Component, Input, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { MasksUtil } from 'src/app/shared/services/masks.util';
import { FormEmissaoNfComponent } from '../form-emissao-nf/form-emissao-nf.component';
import { NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-form-dados-cartao',
  templateUrl: './form-dados-cartao.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormEmissaoNfComponent,
    NgbTooltipModule,
    FeatherModule,
    NgbAccordionModule
  ],
  styles: ``
})
export class FormDadosCartaoComponent {
  //variáveis de ambiente
  formDadosCartao: FormGroup;
  isUtilizarMesmoEmail: boolean = false;
  isUtilizarMesmoNome: boolean = false;
  isUtilizarMesmoCelular: boolean = false;
  @Input() emailUsuario?: string;
  @Input() nomeUsuario?: string;
  @Input() telefoneUsuario?: string;
  isEmitirNf: boolean = false;
  helpLink: string = 'https://doc.extratoofx.com/docs/tutorial-utilizando/planos#informa%C3%A7%C3%B5es-de-pagamento'
  isGerarLinkPagamento: boolean = false;

  static dadosCartao = new Subject<DadosCartaoV1Model>();

  constructor(
    private fb: FormBuilder,
    public formReativo: FormReativoService,
    public masksUtils: MasksUtil,
    private renderer: Renderer2
  ) {
    this.formDadosCartao = this.fb.group(this.montarFormDadosCartao());
  }

  montarFormDadosCartao() {
    return {
      nomeTitular: [
        '',
        Validators.required
      ],
      cpfCnpjTitular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(18)
        ])
      ],
      emailTitular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      celular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ])
      ],
      numeroCartao: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(16),
          Validators.maxLength(16)
        ])
      ],
      cvv: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(3)
        ])
      ],
      validade: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6)
        ])
      ],
    }
  }

  get controls() {
    return this.formDadosCartao.controls;
  }

  onChangeGerarLinkPagamento(){
    if(this.isGerarLinkPagamento!){
      this.formReativo.removeControlsFromForm(this.formDadosCartao, 'cvv', 'numeroCartao', 'validade')
    }else{
      this.formReativo.addControlsToForm(this.formDadosCartao, '', [Validators.required], 'cvv', 'numeroCartao', 'validade')
    }

    this.emitirDadosCartao();
  }

  emitirDadosCartao() {
    if (this.formDadosCartao.valid) {
      if (this.isGerarLinkPagamento!) {
        const dadosAEmitir = {
          nomeTitular: this.controls['nomeTitular'].value,
          celular: this.controls['celular'].value,
          cpfCnpj: this.controls['cpfCnpjTitular'].value,
          emailTitular: this.controls['emailTitular'].value,
        }
        FormDadosCartaoComponent.dadosCartao.next(dadosAEmitir);
      } else {
        if (this.verificarValidadeInformada(this.controls['validade'].value)) {
          const dadosAEmitir = {
            nomeTitular: this.controls['nomeTitular'].value,
            celular: this.controls['celular'].value,
            cpfCnpj: this.controls['cpfCnpjTitular'].value,
            emailTitular: this.controls['emailTitular'].value,
            cvv: this.controls['cvv'].value,
            numeroCartao: this.controls['numeroCartao'].value,
            validade: this.controls['validade'].value,
            cpfUsuario: this.controls['cpfCnpjTitular'].value
          }
          FormDadosCartaoComponent.dadosCartao.next(dadosAEmitir);
        } else {
          this.controls['validade'].setErrors({ notEqual: true });
        }
      }
    }
  }

  verificarValidadeInformada(validade: string) {
    const mes = validade.slice(0, 2);
    const ano = validade.slice(2);

    const dataInformada = new Date(parseInt(ano), (parseInt(mes) - 1));
    const dataAtual = new Date();

    if (dataInformada.toISOString() > dataAtual.toISOString()) {
      /**Se a data informada for maior que a data de hoje */
      return true
    } else {
      return false
    }
  }

  emitirNF() {
    this.isEmitirNf = !this.isEmitirNf;
    if (!this.isEmitirNf) {
      FormEmissaoNfComponent.dadosEmissaoNF.next(undefined);
    }
  }

  utilizarMesmoEmail() {
    if (this.isUtilizarMesmoEmail) {
      this.controls['emailTitular'].setValue(
        this.emailUsuario
      );
      this.renderer.selectRootElement('#celular')?.focus();
    } else {
      this.controls['emailTitular'].reset();
    }
  }

  utilizarMesmoNome() {
    if (this.isUtilizarMesmoNome) {
      this.controls['nomeTitular'].setValue(
        this.nomeUsuario
      );
      this.renderer.selectRootElement('#cpfCnpjTitular')?.focus();
    } else {
      this.controls['cpfCnpjTitular'].reset();
    }
  }

  utilizarMesmoCelular() {
    if (this.isUtilizarMesmoEmail) {
      this.controls['celular'].setValue(
        this.telefoneUsuario
      );
    } else {
      this.controls['celular'].reset();
    }
  }

  proximoInput(formControl: string, maxLength: number, proxInput: string) {
    if (this.controls[formControl]?.value.length == maxLength) {
      this.renderer.selectRootElement(`#${proxInput}`)?.focus();
    }
  };
}

interface DadosCartaoV1Model {
  nomeTitular: string;
  cpfCnpj: string;
  celular: string;
  emailTitular: string;
  numeroCartao?: string;
  cvv?: string;
  validade?: string;
  cpfUsuario?: string;
}