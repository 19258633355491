<div [ngClass]="{'card border-0 rounded shadow' : !isOffCanvas}">
  <div [ngClass]="isOffCanvas! ? 'px-2' : 'card-body'">
    @if(!isOffCanvas){
    <h6>Detalhes da Assinatura:</h6>
    <hr>
    }
    <div class="row g-3 mb-3">
      <div class="col-12 col-xl-6">
        <label class="form-label">Tipo de Cobrança:</label>
        <input type="text" class="form-control form-control-sm fw-bold" readonly
          [value]="planoSelecionado.planoObject.titulo">
      </div>
      <div class="col-12 col-xl-6">
        <label class="form-label">Valor da Cobrança:</label>
        <input type="text" class="form-control form-control-sm fw-bold" readonly
          [ngClass]="planoSelecionado.isCupomAplicado ? 'text-danger' : 'text-success' "
          [value]="'R$' + formatandoNumberParaStringEmReal(planoSelecionado.planoObject.valor)">
      </div>
    </div>
    @if(planoSelecionado.isCupomAplicado){
    <div class="row g-3 mb-3">
      <div class="col-12 col-xl-6">
        <label class="form-label">Desconto:</label>
        <input type="text" class="form-control form-control-sm fw-bold" readonly
          [value]="'R$' + definirDescontoRecebido()">
      </div>
      <div class="col-12 col-xl-6">
        <label class="form-label">Valor Final:</label>
        <input type="text" class="form-control text-success form-control-sm fw-bold" readonly
          [value]="'R$' + formatandoNumberParaStringEmReal(planoSelecionado.valorFinal)">
      </div>
    </div>
    <ngb-alert class="alert bg-soft-success fw-lighter mb-3" [dismissible]="false">
      <i class="uil uil-info-circle lg-5 align-middle me-1"></i>
      Você recebeu <span class="fw-bold">R${{definirDescontoRecebido()}}</span> de desconto, devido ao cupom
      utilizado
      "{{planoSelecionado.cupomAplicado.codecupom}}"
    </ngb-alert>
    }
    <div class="d-grid">
      <button type="button" aria-hidden="false" class="btn mt-3 btn-sm"
        [ngClass]="isDescontoAplicado ? 'btn-danger' : 'btn-warning'"
        (click)="isDescontoAplicado ? removerCupom() : abrirModalInserirCupom()"> 
        {{isDescontoAplicado ? 'Remover Cupom' : 'Possui cupom de desconto?'}}
      </button>
      <button type="button" aria-hidden="false" class="btn mt-1 btn-sm btn-primary"
        (click)="voltarASelecaoDePlano()"> 
        Selecionar outro plano
      </button>
    </div>
  </div>
</div>