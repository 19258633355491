<app-nav-bar [Menuoption]="Menuoption" [Settingicon]="Settingicon"></app-nav-bar>

<div [ngClass]="{'px-2': isMobile}">
  <section id="home" [ngClass]="{'bg-home' : !isMobile, 'mt-5' : isMobile}">
    <section class="container-fluid text-center text-md-start container-md section">
      <app-solucoes-simples-para-necessidades-extremas></app-solucoes-simples-para-necessidades-extremas>
    </section>
  </section>
</div>

<section class="section p-5 bg-home-blue" id="testimonial">
  <app-logo-bancos></app-logo-bancos>
  <!-- <app-casos-de-sucesso></app-casos-de-sucesso> -->
</section>

<section class="container-fluid container-md section p-5 pb-0 pb-lg-5">
  <app-conversoes-disponiveis></app-conversoes-disponiveis>
</section>

<!-- <section class="section  p-5" id="quero-fazer-teste" style="background-color: #fcf3b3;">
     <app-quero-fazer-teste></app-quero-fazer-teste>
  </section> -->

@if(!isMobile){
<section class="section pt-5 pb-0 bg-home-blue" id="comofunciona">
  <app-como-funciona></app-como-funciona>
</section>
}

<section class="section p-3" id="pricing">
  <app-precos [isAutenticado]="false"></app-precos>
  <!-- <app-quero-mais-vantagens></app-quero-mais-vantagens> -->
</section>

<app-botao-back-to-top scrollTo="#home"></app-botao-back-to-top>

<app-footer [footerVariant]="footerVariant" [hideFooter]="hideFooter"></app-footer>