import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_CONVERSOR } from "src/app/_core/api";
import { SecurityUtil } from "src/app/_core/utils/security.util";
import { ResultV1Model } from "src/app/shared/models/result-v1.model";

@Injectable({
  providedIn: 'root'
})
export class PlansV2Service {

  constructor(
    private http: HttpClient,
    private securityUtil: SecurityUtil,
  ) { }

  getPlansDefault() {
    return this.http.get<ResultV1Model>(
      `${API_CONVERSOR}/v2/plans/ativo`,
      { headers: this.securityUtil.composeHeaderConversor() }
    );
  }
}
