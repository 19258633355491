<!--offcanvas Detalhes da Assinatura-->
<div class="d-lg-none">
  <a data-bs-toggle="offcanvas" href="#off-canvas-detalhes-assinatura" role="button"
    aria-controls="off-canvas-detalhes-assinatura">
    <ul id="menu" class="list-unstyled bg-primary py-2 px-3 rounded">
      <li class="text-nowrap text-light">Detalhes da Assinatura</li>
    </ul>
  </a>

  <div class="offcanvas offcanvas-end bg-pastel rounded-start-4" data-bs-scroll="false" tabindex="-1"
    id="off-canvas-detalhes-assinatura" aria-labelledby="off-canvas-detalhes-assinaturaLabel">
    <div class="offcanvas-header justify-content-between pb-0">
      <h6 class="offcanvas-title text-muted">
        Detalhes da Assinatura
      </h6>
      <button class="btn text-primary" data-bs-dismiss="offcanvas" aria-label="Fechar Menu" (click)="removeShow()">
        <i-feather name="x-circle" class="icon-sm"></i-feather>
      </button>
    </div>
    <div class="offcanvas-body mb-2 pt-0 px-2">
      <app-detalhes-assinatura [isOffCanvas]="true"></app-detalhes-assinatura>
    </div>
  </div>
</div>
<!--fim offcanvas Detalhes da Assinatura-->

<div class="row container-lg g-3 px-0 mb-3" id="form-contratar">
  <div class="col-12">
    <div class="row">
      <div class="col-md-4">
        <ul class="nav nav-pills nav-justified flex-column rounded shadow p-3 mb-2 mb-md-0 sticky-bar" id="pills-tab"
          role="tablist">
          <li class="nav-item">
            <a class="nav-link rounded active" id="select-dados-cartao" data-bs-toggle="pill"
              href="#dados-cartao" role="tab"
              aria-controls="dados-cartao" aria-selected="false">
              <div class="text-center py-1">
                <h6 class="mb-0">
                  Dados para Pagamento
                </h6>
              </div>
            </a>
          </li>

          <li class="nav-item mt-2">
            <a class="nav-link rounded" id="select-endereco-faturamento" data-bs-toggle="pill"
              href="#endereco-faturamento" role="tab" aria-controls="endereco-faturamento" aria-selected="false"
              [ngClass]="{'disabled' : (!isDadosCartaoPreenchido && !isDadosTitularPreenchido)}">
              <div class="text-center py-1">
                <h6 class="mb-0">Endereço de Faturamento</h6>
              </div>
            </a>
          </li>
        </ul>

        <div class="d-none d-md-block mt-2">
          <app-detalhes-assinatura></app-detalhes-assinatura>
        </div>
      </div>

      <div class="col-md-8 col-12">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active p-4 rounded shadow" id="dados-cartao" role="tabpanel"
            aria-labelledby="select-dados-cartao">
            <app-form-dados-cartao [emailUsuario]="userAccount?._user?.email" [nomeUsuario]="userAccount?.nome"
              [telefoneUsuario]="userAccount?.celular"></app-form-dados-cartao>
            <div class="text-end mt-3 mt-lg-0">
              <button type="button" class="btn btn-sm btn-success mt-2 mb-0"
                [disabled]="!isDadosCartaoPreenchido"
                (click)="avancarEtapa(1)">
                Próxima Etapa
              </button>
            </div>
          </div>

          <div class="tab-pane fade p-4 rounded shadow" id="endereco-faturamento" role="tabpanel"
            aria-labelledby="select-endereco-faturamento">
            <app-form-endereco-faturamento></app-form-endereco-faturamento>
            <div class="text-end">
              <div class="btn-group mb-0 mt-3">
                <button type="button" class="btn btn-sm btn-secondary" (click)="voltarEtapa(2)">
                  Voltar Etapa
                </button>
                <button type="button" class="btn btn-sm btn-success"
                  [disabled]="!isEnderecoFaturamentoPreenchido || !isDadosCartaoPreenchido"
                  (click)="finalizarContratacao()">
                  Confirmar Assinatura
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="container text-center my-3">
          <div class="row g-3 justify-content-md-center">
            <div class="col-12 col-md-4 col-lg-4">
              <div class="picsum-img-wrapper text-center filter-img">
                <div class="d-none d-md-block">&nbsp;</div>
                <a target="_blank" href="https://www.ssllabs.com/ssltest/analyze.html?d=extratoofx.com&latest">
                  <img src="assets/imgs/certificado.png" [height]="50"
                    alt="um cadeado em verde, fechado, que indica que o site possui o certificado ssl, o tornando um site seguro" />
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4">
              <div class="picsum-img-wrapper text-center filter-img">
                <div id="asaas" class="form-text">Gestão de cobranças</div>
                <a target="_blank" href="https://www.asaas.com/sobre-nos">
                  <img src="assets/imgs/asaas.png" [height]="50" alt="logo do banco asaas" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>