import { Component } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  standalone: true,
  imports: [
    FeatherModule
  ]
})

export class LoaderComponent {
  isAindaCarregando: boolean = false;
  isShowMessage: boolean;
  public show: boolean = false;

  constructor(
    private loaderService: LoaderService
  ) {
    this.loaderService.eventLoader
      .subscribe((v) => {
        this.show = v;
        setTimeout(() => {
          this.isAindaCarregando = v;
        }, 3000);
      });

    loaderService.isShowMessage.subscribe(op => {
      this.isShowMessage = op;
    })
  }
}
