import { ConfiguracoesEnvioNfAsaasV1Model } from "../../models/configuracoes-envio-nf-asaas-v1.model";

export const ConfiguracoesPadraoNF: ConfiguracoesEnvioNfAsaasV1Model = {
  municipalServiceId: null,
  municipalServiceCode: "1.06",
  municipalServiceName: "Assessoria e consultoria em informática.",
  deductions: 0,
  effectiveDatePeriod: "ON_PAYMENT_CONFIRMATION",
  daysBeforeDueDate: null,
  receivedOnly: false,
  observations: null,
  taxes: {
    retainIss: true,
    cofins: 0,
    csll: 0,
    inss: 0,
    ir: 0,
    pis: 0,
    iss: 2.5
  }
}